import React from "react";
import { Link } from "gatsby";
import { Parser } from "html-to-react";
import SVG, { Props as SVGProps } from "react-inlinesvg";
import styled from "@emotion/styled";
import { formatMonthYear } from "../helpers";
import variables from "../variables";
import { css } from "@emotion/react";

const htmlToReactParser = new Parser();

const MenuContainer = styled.div`
  width: 100%;
  color: white;
  background: var(--clay);
  position: ${(props) => (props.isHome ? "relative" : "fixed")};
  top: 0;
  left: 0;
  padding: 1rem 1rem 1rem 1rem;
  .logo-link {
    margin-right: 2rem;
    :nth-child(2) {
      margin-right: 1.8rem;
    }
  }
  svg {
    fill: white;
    --width: 0;
    --height: 0;
    --ideal-area: 80000;
    --area: calc(var(--width) * var(--height));
    --ratio: calc(var(--ideal-area) / var(--area));
    --guess01: calc(calc(var(--ratio) + calc(var(--ratio) / var(--ratio))) / 2);
    --guess02: calc(
      calc(var(--guess01) + calc(var(--ratio) / var(--guess01))) / 2
    );
    --guess03: calc(
      calc(var(--guess02) + calc(var(--ratio) / var(--guess02))) / 2
    );
    --guess04: calc(
      calc(var(--guess03) + calc(var(--ratio) / var(--guess03))) / 2
    );
    --guess05: calc(
      calc(var(--guess04) + calc(var(--ratio) / var(--guess04))) / 2
    );
    --guess06: calc(
      calc(var(--guess05) + calc(var(--ratio) / var(--guess05))) / 2
    );
    --guess07: calc(
      calc(var(--guess06) + calc(var(--ratio) / var(--guess06))) / 2
    );
    --guess08: calc(
      calc(var(--guess07) + calc(var(--ratio) / var(--guess07))) / 2
    );
    max-width: calc(var(--width) * var(--guess08) / 2 * 1px);
    @media screen and (max-width: ${variables.bpSmall}px) {
      --ideal-area: 30000;
    }
  }
`;

const ConferenceButton = styled(Link)`
  display: grid;
  grid-template-columns: 1fr auto;
  border: 2px solid white;
  font-size: 1.3rem;
  padding: 0.8rem 1.2rem;
  border-radius: 500px;
  &:not(:first-of-type) {
    margin-top: 0.25rem;
  }
  &:hover {
    background: var(--white);
    color: var(--clay);
  }
  @media screen and (max-width: ${variables.bpXXSmall}px) {
    border-radius: 30px;
    grid-template-columns: 1fr;
    text-align: right;
    gap: 0.2rem;
  }
`;

const ConferenceNumber = styled.span`
  display: inline-block;
  width: 100%;
`;

const ConferenceMonth = styled.span`
  display: inline-block;
`;

const PublicEventsBar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 0.25rem;
  font-size: 1.3rem;
  border-radius: 500px;
  @media screen and (max-width: ${variables.bpXXSmall}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const PublicEventsTitle = styled.span`
  display: inline-block;
  padding: 0.8rem 0 0.8rem 1.3rem;
  width: 100%;
  @media screen and (max-width: ${variables.bpXXSmall}px) {
    ${
      "" /* text-align: right;
    padding: 0.8rem 1.2rem;
    margin-bottom: 0.25rem;
    text-decoration: underline;
    text-underline-offset: 0.2rem; */
    }
    display: none;
  }
`;

const PublicEventButton = styled(Link)`
  display: inline-block;
  grid-column: 2;
  width: fit-content;
  border: 2px solid white;
  font-size: 1.3rem;
  padding: 0.8rem 1.2rem;
  border-radius: 500px;
  &:hover {
    background: var(--white);
    color: var(--clay);
  }
  &.button-disabled {
    ${"" /* border-color: rgba(255, 255, 255, 0.2); */}
    position: relative;
    &:before {
      content: "Recently Updated";
      display: flex;
      font-size: 0.5rem;
      text-transform: uppercase;
      text-align: center;
      width: 2.5rem;
      height: 2.5rem;
      background: var(--white);
      color: var(--black);
      align-items: center;
      justify-content: center;
      padding: 12px 10px 7px;
      border-radius: 500px;
      position: absolute;
      top: -1px;
      left: -3.5rem;
    }
    @media screen and (max-width: ${variables.bpMed}px) {
      &:before {
        width: 2.3rem;
        height: 2.3rem;
        left: -3.7rem;
        top: -2px;
      }
    }
    @media screen and (max-width: ${variables.bpXSmall}px) {
      &:before {
        width: 2rem;
        height: 2rem;
        left: -3.65rem;
      }
    }
  }
`;

const AboutResourceContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: ${variables.bpSmall}px) {
    flex-direction: column-reverse;
    align-items: initial;
  }
`;

const AboutButton = styled(Link)`
  padding: 1.2rem 1.2rem;
  border: 2px solid white;
  border-radius: 50px;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  flex-basis: 50%;
  h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  &:hover {
    background: var(--white);
    color: var(--clay);
  }
  @media screen and (max-width: ${variables.bpMed}px) {
    border-radius: 40px;
  }
  @media screen and (max-width: ${variables.bpSmall}px) {
    border-radius: 30px;
    margin-right: initial;
  }
`;

const ResourcesButton = styled(Link)`
  padding: 0.8rem 1.2rem;
  border: 2px solid white;
  border-radius: 50px;
  margin-top: 0.25rem;
  font-size: 1.3rem;
  &:hover {
    background: var(--white);
    color: var(--clay);
  }
`;

const LogoContainer = styled.div`
  margin-top: 6rem;
  @media screen and (max-width: ${variables.bpSmall}px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      display: block;
      width: 100%;
      text-align: center;
    }
    .logo-link:not(:last-child) {
      margin-right: 0;
      margin-bottom: 2rem;
    }
    .logo-link:last-child {
      margin-bottom: 4rem;
    }
  }
`;

export default function Menu({
  isHome,
  menuItems,
  aboutText,
  logos,
  showMenu,
  setShowMenu,
}) {
  return (
    <MenuContainer
      name='homeMenu'
      isHome={isHome}
      style={{
        height: showMenu ? "100vh" : "initial",
        minHeight: showMenu ? "initial" : "100vh",
        overflowY: showMenu ? "scroll" : "hidden",
      }}
    >
      <div
        css={css`
          max-width: 50rem;
          height: auto;
          margin-right: ${showMenu ? "7rem" : 0};
          margin-left: ${showMenu ? 0 : "auto"};
          @media screen and (max-width: 840px) {
            margin-right: 0;
            margin-top: ${isHome ? "0" : "3rem"};
          }
        `}
      >
        {menuItems.map((mi, i, a) => {
          if (mi.type === "conference") {
            return (
              <ConferenceButton
                key={i}
                to={`/conferences/${mi.slug}`}
                onClick={() => {
                  setShowMenu(false);
                }}
              >
                <ConferenceNumber>
                  Conference #{mi.conferenceIndex + 1}
                </ConferenceNumber>
                <ConferenceMonth>
                  {formatMonthYear(new Date(Date.parse(mi.startDate)))}
                </ConferenceMonth>
              </ConferenceButton>
            );
          } else if (mi.type === "event") {
            if (a[i - 1].type === "conference") {
              return (
                <PublicEventsBar key={i}>
                  <PublicEventsTitle>Public Events</PublicEventsTitle>
                  <PublicEventButton
                    className={mi.isLive && "button-disabled"}
                    onClick={(e) => {
                      setShowMenu(false);
                    }}
                    to={`/public-events/${mi.slug}`}
                  >
                    {mi.title}
                  </PublicEventButton>
                </PublicEventsBar>
              );
            } else {
              console.log(mi.isLive);
              return (
                <PublicEventsBar key={i}>
                  <PublicEventButton
                    className={mi.isLive && "button-disabled"}
                    onClick={(e) => {
                      setShowMenu(false);
                    }}
                    to={`/public-events/${mi.slug}`}
                  >
                    {mi.title}
                  </PublicEventButton>
                </PublicEventsBar>
              );
            }
          } else {
            return null;
          }
        })}
        <AboutResourceContainer>
          <AboutButton
            to='/about'
            onClick={() => {
              setShowMenu(false);
            }}
          >
            <h2>About</h2>
            {htmlToReactParser.parse(aboutText)}
          </AboutButton>
          <ResourcesButton
            to='/resources'
            onClick={() => {
              setShowMenu(false);
            }}
          >
            Resources
          </ResourcesButton>
        </AboutResourceContainer>
        <LogoContainer>
          {logos.map((l, i) => {
            return (
              <a
                key={`logo-${i}`}
                className='logo-link'
                href={l.logoLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                <SVG
                  style={{ "--width": l.width, "--height": l.height }}
                  cacheRequests={true}
                  loader={<span>Loading...</span>}
                  src={l.url}
                  title={l.title}
                />
              </a>
            );
          })}
        </LogoContainer>
      </div>
    </MenuContainer>
  );
}
